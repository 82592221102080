import React, {Suspense, lazy} from 'react'
import {Routes as Switch, Route} from 'react-router-dom'
import styled from 'styled-components/macro'

import LoadingSpinner from './assets/Spinner'
import ScrollToTop from 'assets/ScrollToTop'
import ScrollingHeaderBar from 'common/ScrollingHeaderBar'
import Footer from 'common/Footer'
import HeaderBar from 'common/HeaderBar'
import ComingSoon from 'marketing/ComingSoon/ComingSoon'
import ReactGA from 'react-ga4'
import useLocationChange from 'common/useLocationChange'
import SunAndFun from 'marketing/Destinations/SunAndFun'

function retry(fn, retriesLeft = 5, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            alert('Network error, please refresh the page')
            console.error('network failure')
            reject(error)
            return
          }
          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

const Home = lazy(() => retry(() => import('marketing/HomePage/Home')))
const HoleInOnePackages = lazy(() =>
  retry(() => import('marketing/HoleInOne/HoleInOnePackages')),
)
const Launcher = lazy(() => retry(() => import('marketing/Launcher/Launcher')))
const Planning = lazy(() => retry(() => import('marketing/Planning/Planning')))
const Gifts = lazy(() => retry(() => import('marketing/Gifts/Gifts')))
const OnCourse = lazy(() => retry(() => import('marketing/OnCourse/OnCourse')))
const Photography = lazy(() =>
  retry(() => import('marketing/Photography/Photography')),
)
const Destinations = lazy(() =>
  retry(() => import('marketing/Destinations/Destinations')),
)
const SweetSpot = lazy(() =>
  retry(() => import('marketing/SweetSpot/SweetSpot')),
)
const EventPlanning = lazy(() =>
  retry(() => import('marketing/EventPlanning/EventPlanning')),
)
const Contests = lazy(() => retry(() => import('marketing/Contests/Contests')))

const CigarRolling = lazy(() =>
  retry(() => import('marketing/CigarRolling/CigarRolling')),
)
const GolferGifts = lazy(() =>
  retry(() => import('marketing/GolferGifts/GolferGifts')),
)
const Contact = lazy(() => retry(() => import('marketing/Contact/Contact')))

// const ClubRentals = lazy(() =>
//   retry(() => import('marketing/ClubRentals/ClubRentals')),
// )
const About = lazy(() => retry(() => import('marketing/About/About')))
const Apparel = lazy(() => retry(() => import('marketing/Apparel/Apparel')))
const SignsBanners = lazy(() =>
  retry(() => import('marketing/SignsBanners/SignsBanners')),
)
const Promotional = lazy(() =>
  retry(() => import('marketing/Promotional/Promotional')),
)

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Wrapper = styled.div`
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  position: relative;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const AppLayout = ({
  navBar = true,
  footer = true,
  children,
  scrollingHeaderBar = true,
  dontScroll = false,
}) => {
  return (
    <RootDiv>
      <ScrollToTop dontScroll={dontScroll}>
        <Wrapper>
          <Container>
            {navBar ? <HeaderBar /> : null}
            {children}
            {footer ? <Footer /> : null}
          </Container>
          {scrollingHeaderBar ? <ScrollingHeaderBar /> : null}
        </Wrapper>
      </ScrollToTop>
    </RootDiv>
  )
}

const Routes = () => {
  useLocationChange(location => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: 'Custom Title',
    })
  })
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route
          exact
          path="/"
          element={
            <AppLayout navBar={false} footer={true} scrollingHeaderBar={true}>
              <Home />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/holeinone"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <HoleInOnePackages />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/destinations"
          element={
            <AppLayout
              navBar={true}
              footer={true}
              scrollingHeaderBar={true}
              dontScroll={true}
            >
              <HoleInOnePackages destinations={true} />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/launcher"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Launcher />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/planning"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Planning />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/destinations/:partner"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Destinations />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/travel/sunandfun"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <SunAndFun />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/gifts"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Gifts />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/gifts/golfer-gifts"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <GolferGifts />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/gifts/apparel"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Apparel />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/gifts/promotional"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Promotional />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/oncourse"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <OnCourse />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/sweetspot"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <SweetSpot />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/event-planning"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <EventPlanning />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/contests"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Contests />
            </AppLayout>
          }
        />
        {/* <Route
          exact
          path="/club-rentals"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <ClubRentals />
            </AppLayout>
          }
        /> */}
        <Route
          exact
          path="/cigar-rolling"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <CigarRolling />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/photography"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Photography />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/employment"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <About employment={true} />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <About employment={false} />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <Contact />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/signs"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <SignsBanners />
            </AppLayout>
          }
        />
        <Route
          exact
          path="/shop"
          element={
            <AppLayout navBar={true} footer={true} scrollingHeaderBar={true}>
              <ComingSoon />
            </AppLayout>
          }
        />
        <Route
          path="*"
          element={
            <AppLayout navBar={false} footer={true} scrollingHeaderBar={true}>
              <Home />
            </AppLayout>
          }
        />
      </Switch>
    </Suspense>
  )
}

export default Routes
