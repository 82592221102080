import * as React from 'react'
import styled from 'styled-components/macro'
import Modal from 'common/Modal'
import {submitQuoteRequest} from 'common/sendEmail'

import {
  Line,
  StyledLabel,
  StyledInput,
  StyledForm,
  Title,
  Button,
  StyledSelect,
} from 'common/ModalFormComponents'

const Wrapper = styled.div`
  padding: 56px;
  color: var(--dark-grey);
  font-family: 'Mulish';
`

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`

const RequestQuotePopup = ({showQuoteModal, setShowQuoteModal}) => {
  const [submitting, setSubmitting] = React.useState(false)
  const [submitSuccess, setSubmitSuccess] = React.useState(false)

  return (
    <Modal isOpen={showQuoteModal} onDismiss={() => setShowQuoteModal(false)}>
      <Wrapper>
        <StyledForm
          name="request-quote"
          method="POST"
          data-netlify={true}
          id="coming-soon"
        >
          <Title>Request a Quote</Title>
          <input name="subject" hidden value={'Request a quote'} readOnly />
          <Line>
            <StyledLabel name="Service" htmlFor="service">
              Which service are you interested in?
            </StyledLabel>
            <StyledSelect name="Service" id="service">
              <option value="Event Planning">Event Planning</option>
              <option value="Sweet Spot Software">Sweet Spot Software</option>
              {/* <option value="Event Liability Coverage">
                Event Liability Coverage
              </option> */}
              <option value="Apparel">Apparel</option>
              <option value="Golfer Gifts">Golfer Gifts</option>
              <option value="Promotional Products">Promotional Products</option>
              <option value="Hole-in-One Packages">Hole-in-One Packages</option>
              <option value="Putting & Shootout Contests">
                Putting & Shootout Contests
              </option>
              <option value="Signs & Banners">Signs & Banners</option>
              <option value="Golf Ball Launcher">Golf Ball Launcher</option>
              <option value="Cigar Rolling">Cigar Rolling</option>
              {/* <option value="Club Rentals">Club Rentals</option> */}
              <option value="Photography">Photography</option>
            </StyledSelect>
          </Line>
          <Line>
            <StyledLabel name="Contact Name" htmlFor="First Name">
              Contact Name
            </StyledLabel>
            <div>
              <StyledInput width="228" name="First Name"></StyledInput>
              <StyledInput width="228" name="Last Name"></StyledInput>
            </div>
          </Line>
          <Line>
            <StyledLabel name="email" htmlFor="email">
              Email
            </StyledLabel>
            <StyledInput width="553" name="email" id="email"></StyledInput>
          </Line>
          <Line>
            <StyledLabel name="Phone" htmlFor="Phone">
              Phone
            </StyledLabel>
            <StyledInput width="546" name="Phone"></StyledInput>
          </Line>
          <ButtonBar>
            {submitSuccess ? (
              <div>Request Sent!</div>
            ) : (
              <Button
                type="button"
                disabled={submitting}
                onClick={() =>
                  submitQuoteRequest({
                    setSubmitSuccess,
                    setSubmitting,
                    formID: 'coming-soon',
                  })
                }
                submitting={submitting}
              >
                {submitting ? 'Sending...' : 'Send'}
              </Button>
            )}
          </ButtonBar>
          <input
            type="hidden"
            name="form-name"
            value="request-quote"
            readOnly
          />
          <input type="hidden" name="Type" value="Coming Soon" readOnly />
        </StyledForm>
      </Wrapper>
    </Modal>
  )
}

export default RequestQuotePopup
