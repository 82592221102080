import * as React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  cursor: initial;
  position: absolute;
  top: 96px;
  left: 0;
  padding: 40px;
  background-color: white;
  color: var(--dark-grey);
  font-family: 'Mulish';
  font-style: normal;
  text-transform: initial;
  gap: 40px;
  border-radius: 5px;
  z-index: 2;
`

const Header = styled.div`
  font-weight: 400;
  font-size: calc(18 / 16 * 1rem);
  line-height: 23px;
  text-decoration: underline;
`

const SecondHeader = styled(Header)`
  margin-top: 22px;
`

const Item = styled(Link)`
  font-weight: 300;
  font-size: calc(18 / 16 * 1rem);
  line-height: 20px;
  white-space: nowrap;
  text-decoration: none;
  color: var(--dark-grey);
  cursor: pointer;
  :hover {
    font-weight: 700;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 220px;
`

const AdditionalHeader = styled(Header)`
  font-style: italic;
  font-weight: 200;
  text-decoration: initial;
`

const AdditionalItem = styled(Item)`
  padding-left: 16px;
`

const TheDarkness = styled.div`
  background-color: hsla(0, 0%, 0%, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
`

const ServicesDropdown = ({visible}) => {
  if (!visible) return null
  return (
    <>
      <Container>
        <Column>
          <Header>Planning</Header>
          <Item to="/event-planning">Event Planning</Item>
          <Item to="/sweetspot">Sweet Spot Software</Item>
          {/* <Item to="/event-liability-coverage">Event Liability Coverage</Item> */}
          <SecondHeader>Gifts</SecondHeader>
          <Item to="/gifts/apparel">Apparel</Item>
          <Item to="/gifts/golfer-gifts">Golfer Gifts</Item>
          <Item to="/gifts/promotional">Promotional Products</Item>
        </Column>
        <Column>
          <Header>On-Course</Header>
          <Item to="/holeinone">Hole-in-One Packages</Item>
          <Item to="/contests">Putting & Shooting Contests</Item>
          <Item to="/signs">Signs & Banners</Item>
          <Item to="/launcher">Golf Ball Launcher</Item>
          <AdditionalHeader>Additional Services</AdditionalHeader>
          <AdditionalItem to="/cigar-rolling">Cigar Rolling</AdditionalItem>
          {/* <AdditionalItem to="/club-rentals">Club Rentals</AdditionalItem> */}
          <AdditionalItem to="/photography">Photography</AdditionalItem>
        </Column>
      </Container>
      <TheDarkness />
    </>
  )
}
export default ServicesDropdown
